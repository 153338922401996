import React from 'react';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Setup from './components/Setup';
import ContactUs from './components/ContactUs';
import PartnerRestaurants from './components/PartnerRestaurants';
import AboutSection from './components/AboutSecton';
import SlideImage from './components/SlideImage';



function App() {
  return (
    <div className="App">
      <Header />
      <Home />
      <PartnerRestaurants/>
      <AboutSection/>
      <SlideImage/>
      <Setup />
      <ContactUs />
      <Footer />
      
    </div>
  );
}

export default App;
