import React from 'react';
import './Home.css'; // Make sure to import the CSS file
import logoHeader from '../images/AA.png'; // Adjust the path as necessary



function Home() {

  // Function to scroll to the bottom of the page
  const scrollToBottom = (pixels) => {
    window.scrollBy({
      top: pixels, // Number of pixels to scroll by
      behavior: 'smooth' // Smooth scroll
    });
  };

  return (
    <div className="home-container">
      <div className="content-area">
        <img src={logoHeader} alt="Logo" className="header-logo" />
        <p className ="textmyguy">DIGITAL MENU VIA a QR CODE</p>

        <h1>EFFORTLESS</h1>
        <h1>QR MENUS</h1>
        <h1>FOR RESTAURANTS</h1>
        <p>Only $19.99/month. Cancel anytime.</p>
        <div className="buttons">
          <button className="trial-button" onClick={() => scrollToBottom(1600)}>How to Start</button>
          <button className="view-plans-button" onClick={() => scrollToBottom(1600)}>View Plan</button>

        </div>
      </div>
      <div className="image-area">
      </div>
    </div>
  );
}

export default Home;
