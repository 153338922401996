import React from 'react';
import './AboutSection.css'; // Ensure CSS is properly imported

function AboutSection() {
  return (
    <div className="about-section">
      <div className="section-header"></div>
      <div className="main-container">
        <div className="main-item">
          <h2>Transforming Way Customers Explore Menus</h2>
          <p>Seamlessly transform physical menus into unique digital restaurant menu through a simple QR code, creating an engaging and efficient way to discover your delicious offerings.
</p>
        </div>
        <div className="side-items">
          <div className="side-item">
            <h2>SUPER EASY SET UP</h2>
            <p>Just send us the physical menu, and we'll arrange everything within a few days. We'll then send you the QR codes—it's quite straightforward!</p>
          </div>
          <div className="side-item">
            <h2>COST SAVING</h2>
            <p>Operational cost savings of up to $700 per month by implementing automation and reducing manual labor.</p>
          </div>
          <div className="side-item">
            <h2>EASY TO MANAGE</h2>
            <p>We make it simple for you to manage and update your menus effortlessly.</p>
          </div>
          <div className="side-item">
            <h2>MORE HYGIENE</h2>
            <p>Contactless menus reduce germ transmission for a safer dining experience.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutSection;
