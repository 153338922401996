
import React from 'react';
import './SlideImage.css'; // Ensure CSS is properly imported


function SlideImage() {
    return (
        <div className="partner-restaurants1">
            <div className="scrolling-wrapper">
                {[...Array(10)].map((_, i) => (
                    <React.Fragment key={i}>
                        <div className='Ye'><h1>SAVE</h1><h2>COST!</h2></div>
                        <div className='Ye'><h1>SIMPLE</h1><h2>MANAGE</h2></div>
                        <div className='Ye'><h1>MORE</h1><h2>EFFICIENT</h2></div>
                        <div className='Ye'><h1>EASY</h1><h2>SET-UP</h2></div>
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
}

export default SlideImage;
