import React, { useState, useEffect } from 'react';
import './Header.css'; // Ensure your CSS path is correct
import logoHeader from '../images/AA2.png'; // Adjust the path as necessary

function Header() {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > 750; // You can adjust this value based on your needs
            setScrolled(isScrolled);
        };

        // Event listener for scroll events
        window.addEventListener('scroll', handleScroll);

        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header className={`header ${scrolled ? 'scrolled' : ''}`}>
            <img src={logoHeader} alt="Logo" className="header-logo" />
        </header>
    );
}

export default Header;
