import React from 'react';
import './ContactUs.css'; // Make sure to import the CSS file for styling

function ContactUs() {
  return (
    <div className="contact-container">
      <div className="contact-text">
        <h1>Contact us to Set Up at...</h1>
        <a href="mailto:scantableqr@gmail.com" className='email'>scantableqr@gmail.com</a>
        <p>Our response time is approximately 1-2 business days. </p>
        <p>Opened from: (Mon - Friday) 10:00 - 21:00</p>
      </div>
    </div>
  );
}

export default ContactUs;
